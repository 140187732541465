<template>
  <span>{{ formatted }}</span>
</template>

<script>
import { DateTime } from "luxon";

export default {
  name: "Timestamp",
  props: {
    time: Number | String,
    format: {
      required: false,
      type: String,
    },
    placeholder: {
      required: false,
      type: String,
    },
  },
  computed: {
    formatted: function () {
      if (this.time) {
        return DateTime.fromSeconds(parseInt(this.time)).toFormat(
          this.format || "dd/MM/yyyy HH:mm:ss"
        );
      }
      return this.placeholder || "";
    },
  },
};
</script>

<style scoped></style>
